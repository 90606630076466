import { useAuthStore } from "~/store/auth.js";

export default async function guest({ next }) {
    const authStore = useAuthStore();

    if (!authStore.guest) {
        // user was logged in before (guest state persisted)

        // check logged in state and redirect
        if (await authStore.getUser()) {
            return next({ name: "profile-dashboard" });
        }

        // if unsuccessful, reset guest state and continue
        authStore.guest = true;
    }

    return next();
}
