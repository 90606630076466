import { useAnalyticsStore } from "~/store/analytics.js";

export const sendPageViewToAnalytics = async (to, from) => {
    const analyticsStore = useAnalyticsStore();
    if (!analyticsStore.consentGiven) return;

    const siteUrl = window.env.SITE_URL.replace(/\/$/, "");
    const digitalData = {
        page: {
            pageInfo: {
                pageURL: siteUrl + to.fullPath,
                pageURLClean: siteUrl + to.path,
                pageTitle: to.meta.title,
                pageName: `${to.meta.title} | ${window.analyticsSolution} | ${to.path}`,
                solution: window.analyticsSolution,
                language: "de",
                referringURL: from.name
                    ? siteUrl + from.fullPath
                    : document.referrer,
                siteSection: "Profilseite",
                siteSectionLevel1: to.meta.section ?? to.meta.title,
                siteSectionLevel2: to.meta.section ? to.meta.title : "",
                siteSectionLevel3: "",
                virtualPage: analyticsStore.virtualPage,
            },
        },
        user: { userAgent: navigator.userAgent },
    };
    // Set the previous page object
    const previousPage = { pageInfo: { pageURL: "", pageName: "" } };
    if (from.name) {
        previousPage.pageInfo = {
            pageURL: siteUrl + from.fullPath,
            pageName: `${from.meta.title} | ${window.analyticsSolution} | ${from.path}`,
        };
    } else if (document.referrer) {
        previousPage.pageInfo.pageURL = document.referrer;
    }
    digitalData.previousPage = previousPage;

    // write the global digitalData object
    window.digitalData = digitalData;

    if (window.env.APP_DEBUG) {
        console.log("Track pageview", digitalData);
    }

    if (typeof _satellite !== "undefined") {
        if (typeof _satellite.track === "function") {
            _satellite.track("page-view");
        } else if (window.env.APP_DEBUG) {
            console.warn("_sattelite is defined but not loaded");
        }
    }

    analyticsStore.virtualPage = true;
};

export const listenForInteraction = (element, callback) => {
    const events = ["input", "click", "change", "keydown", "touchstart"];
    let removeListeners = () => {};

    const listener = () => {
        callback();
        removeListeners();
    };

    removeListeners = () => {
        events.forEach((event) => element.removeEventListener(event, listener));
    };

    if (element) {
        events.forEach((event) =>
            element.addEventListener(event, listener, { once: true }),
        );
    }
};

export const sendApplicationStepToAnalytics = async function ({
    applicationName,
    type = "start",
    stepName = "Step 1",
    configuration = "",
}) {
    const analyticsStore = useAnalyticsStore();
    if (!analyticsStore.consentGiven) return;

    if (window.env.APP_DEBUG) {
        console.log("Track application step", arguments[0]);
    }

    if (typeof _satellite !== "undefined") {
        _satellite.track("application", arguments[0]);
    }
};

export const sendFormStepToAnalytics = async ({
    formName,
    type = "start",
    step = 1,
}) => {
    const analyticsStore = useAnalyticsStore();
    if (!analyticsStore.consentGiven) return;

    const trackObject = { type, form: { name: formName, step } };

    if (window.env.APP_DEBUG) {
        console.log("Track form", trackObject);
    }

    if (typeof _satellite !== "undefined") {
        _satellite.track("form", trackObject);
    }
};
