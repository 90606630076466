<template>
    <div
        v-if="uiStore.checkin.showSuccess"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
        class="fixed inset-0 z-20 overflow-y-auto text-white"
    >
        <!-- Overlay -->
        <div class="fixed inset-0 bg-secondary" />

        <!-- Panel -->
        <div
            class="container relative flex items-center justify-center min-h-screen p-4"
        >
            <div class="w-full md:w-1/2 space-y-4 md:space-y-8">
                <div class="h-14 md:h-20">
                    <svg
                        height="100%"
                        viewBox="0 0 107 85"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2 28L30.3815 47L105 2L30.3815 82L2 28Z"
                            class="stroke-white"
                            stroke-width="3"
                        />
                    </svg>
                </div>
                <h2 class="heading-lg">
                    {{ $t("checkin.checkinSuccessHeading") }}
                </h2>
                <router-link
                    :to="{ name: 'profile-dashboard' }"
                    class="button button-primary"
                    @click="uiStore.hideCheckinSuccessModal"
                >
                    {{ $t("checkin.toProfileButton") }}
                </router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import { watchEffect } from "vue";
import { useUIStore } from "~/store/ui.js";

const uiStore = useUIStore();

watchEffect(() => {
    if (uiStore.checkin.showSuccess) {
        document.body.classList.add("overflow-hidden");
    } else {
        document.body.classList.remove("overflow-hidden");
    }
});
</script>
