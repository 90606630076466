import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import { flare } from "@flareapp/js";
import router from "~/router/index.js";
import i18n from "~/lang/index.js";
import MasonryGrid from "~/layout/MasonryGrid.js";
import Flickity from "~/layout/Flickity.js";
import Photoswipe from "~/layout/Photoswipe.js";
import CookieConsent from "~/analytics/CookieConsent.js";
import BookingToolApp from "~/components/global/BookingToolApp.vue";
import Accordion from "~/components/global/Accordion.vue";
import HeroService from "~/components/global/HeroService.vue";
import UserIcon from "~/components/global/UserIcon.vue";
import "htmx.org";
import "lazysizes";
import "lazysizes/plugins/optimumx/ls.optimumx";

if (process.env.NODE_ENV === "production") {
    flare.light();
}

const pinia = createPinia();
pinia.use(piniaPersist);

// Mount Vue instances
[
    {
        selector: "[data-vue-bookingtool]",
        app: BookingToolApp,
        uses: [pinia, router, i18n],
    },
    {
        selector: "[data-vue-accordion]",
        app: { components: { Accordion } },
        uses: [],
    },
    {
        selector: "[data-vue-hero-service]",
        app: { components: { HeroService } },
        uses: [],
    },
    {
        selector: "[data-vue-user-icon]",
        app: { components: { UserIcon } },
        uses: [pinia],
    },
].forEach(({ selector, app, uses }) => {
    document.querySelectorAll(selector).forEach((el) => {
        const vueInstance = createApp(app);

        uses.forEach((use) => vueInstance.use(use));

        vueInstance.config.compilerOptions.delimiters = ["${", "}"];
        vueInstance.mount(el);
    });
});

/**
 * If a URL contains a hash, make the website scroll there
 * (fix for firefox)
 * pageshow fires after load and on Back/Forward
 */
window.addEventListener("pageshow", () => {
    const elId = window.location.hash;
    if (elId.length > 1) {
        const el = document.getElementById(elId.substring(1));
        if (el) el.scrollIntoView(true);
    }
});

// Global layout tools
MasonryGrid.init();
Flickity.init();
Photoswipe.init();

// Load cookie banner
CookieConsent.init();
