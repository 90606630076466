import { defineStore } from "pinia";
import { logout, user } from "~/services/auth.js";
import router from "~/router/index.js";

export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            user: null,
            guest: true,
        };
    },

    getters: {
        isLoggedIn: (state) => !state.guest && state.hasUser,
        isVerified: (state) =>
            !state.guest && state.hasUser && state.user.phoneNumberVerified,
        hasUser: (state) => state.user !== null,
        remainingContingent: (state) =>
            state.user?.totalContingent - state.user?.currentUserContingent,
        contingentReached: (state) =>
            state.user?.currentUserContingent >= state.user?.totalContingent,
    },

    actions: {
        logout() {
            logout()
                .then(() => {})
                .finally(() => {
                    this.user = null;
                    this.guest = true;
                    router.push({ name: "login" });
                });
        },

        async getUser() {
            try {
                this.user = await user();
                this.guest = false;
                return user;
            } catch (error) {
                this.user = null;
                this.guest = true;
                return null;
            }
        },
        revalidate() {
            return this.getUser();
        },
    },
    persist: {
        enabled: true,
        paths: [
            "guest",
            // By persisting the user, the UserIcon component doesn't need
            // to make an api request on each page load.
            "user",
        ],
        strategies: [
            { storage: sessionStorage, paths: ["user"] },
            { storage: localStorage, paths: ["guest"] },
        ],
    },
});
