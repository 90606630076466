import { useAuthStore } from "~/store/auth.js";

export default async function auth({ to, next }) {
    const loginQuery = { name: "login", query: { redirect: to.name } };
    const dashboardQuery = { name: "profile-dashboard" };
    const authStore = useAuthStore();

    // Always check for the logged in state
    await authStore.getUser();

    // Redirect to the login screen if not authenticated
    if (!authStore.hasUser) {
        return next(loginQuery);
    }

    // If a user has already verified their phone number
    // redirect to the dashboard
    if (authStore.isVerified) {
        return next(dashboardQuery);
    }

    return next();
}
