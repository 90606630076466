import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/dist/photoswipe.css";

const SEL_PHOTOSWIPE = "[data-photoswipe-gallery]";
const SEL_PHOTOSWIPE_ITEM = "[data-photoswipe-item]";
const closeSVG = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L16 16.1688M16 16.1688L30 2M16 16.1688L30 30M16 16.1688L2 30" stroke="#516D75" stroke-width="3"/>
</svg>
`;

export default {
    lightboxes: [],

    init() {
        this.loadPhotoswipeGalleries();
    },

    loadPhotoswipeGalleries() {
        const photoswipeGalleries = document.querySelectorAll(SEL_PHOTOSWIPE);
        if (!photoswipeGalleries.length) return;

        photoswipeGalleries.forEach((gallery) => {
            const children = gallery.querySelectorAll(SEL_PHOTOSWIPE_ITEM);
            const lightbox = new PhotoSwipeLightbox({
                gallery,
                children,
                pswpModule: PhotoSwipe,
                zoom: false,
                counter: false,
                bgOpacity: 0.9,
                closeSVG,
                padding: { top: 80, bottom: 80, left: 20, right: 20 },
            });

            lightbox.on("uiRegister", () => {
                lightbox.pswp.ui.registerElement({
                    name: "custom-caption",
                    order: 9,
                    isButton: false,
                    appendTo: "root",
                    html: "Caption text",
                    onInit: (el, pswp) => {
                        lightbox.pswp.on("change", () => {
                            const currSlideElement =
                                lightbox.pswp.currSlide.data.element;
                            el.innerHTML = currSlideElement
                                ? currSlideElement.getAttribute("data-caption")
                                : "";
                        });
                    },
                });
            });

            lightbox.init();
            this.lightboxes.push(lightbox);
        });
    },
};
