import axios from "~/lib/axios";
import { handleAPIErrors } from "~/utils/errors.js";
import {
    formatDateISO,
    parseUtcDateFromBackend,
    convertDateToUtcForBackend,
} from "~/utils/dates.js";

/**
 * Get all days to show in the Date Selector
 * If a space is already selected, Unavailable days are marked "unavailable".
 * Also the number of days vary by space.
 */
export const getDates = async ({
    errors = null,
    space = null,
    bookingId = null,
} = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.get("/api/v1/bookingtool/dates", {
            params: {
                space_id: space?.id,
                booking_id: bookingId,
            },
            cache: { ttl: 10 * 1000 },
        });
        return result.data.data.map((date) => ({
            ...date,
            // date: parseISO(date.date),
            date: parseUtcDateFromBackend(date.date),
        }));
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Get all spaces to show in the Space Selector.
 * If a date is already selected, unavailable spaces on that day are marked "unavailable"
 */
export const getSpaces = async ({ errors = null, date = null } = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.get("/api/v1/bookingtool/spaces", {
            params: { date: date && formatDateISO(date) },
            cache: { ttl: 10 * 1000 },
        });
        return result.data.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Gets the available, bookable timeslots on a given date for a given space.
 */
export const getTimeblocks = async ({
    errors = null,
    date = null,
    space = null,
    ignoredBookingIds = [],
    bookingId = null,
} = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.get("/api/v1/bookingtool/timeblocks", {
            params: {
                space_id: space?.id,
                date: date && formatDateISO(date),
                ignored_booking_ids: ignoredBookingIds,
                booking_id: bookingId,
            },
            cache: { ttl: 10 * 1000 },
        });
        return result.data.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Creates a new booking and stores it in the backend
 */
export const createBooking = async ({ errors = null, payload = {} } = {}) => {
    if (errors) errors.value = [];
    try {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const result = await axios.post("/api/v1/bookings", {
            date_from: convertDateToUtcForBackend(payload.dateFrom),
            date_to: convertDateToUtcForBackend(payload.dateTo),
            space_id: payload.space.id,
            message: payload.message,
            topic: payload.topic,
            max_participants: payload.maxParticipants,
        });
        return result.data.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Creates a new booking and stores it in the backend
 */
export const updateBooking = async ({
    errors = null,
    bookingId,
    payload = {},
} = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.put(`/api/v1/bookings/${bookingId}`, {
            date_from: convertDateToUtcForBackend(payload.dateFrom),
            date_to: convertDateToUtcForBackend(payload.dateTo),
        });
        return result.data.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Get CMS Data for the space
 */
export const getSpaceCMSData = async ({ errors = null, slug = null } = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.get(`api/v1/elements/spaces/${slug}`, {
            baseURL: window.location.origin,
            // cache: { ttl: 0 },
        });
        return result.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Get general config settings from the bookingtool backend
 */
export const getConfig = async ({ errors = null } = {}) => {
    if (errors) errors.value = [];
    try {
        const result = await axios.get("/api/v1/bookingtool/config", {
            cache: { ttl: 10 * 1000 },
        });
        return result.data.data;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};
