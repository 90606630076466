<template>
    <a
        class="block rounded-full transition-all hover:opacity-60"
        href="/app/profil"
        @click.prevent="goToProfile"
    >
        <div v-if="authStore.isLoggedIn" class="relative">
            <IconNavUserAuthenticated />
            <div
                class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[3px] leading-[12px] text-[12px]"
            >
                {{ initials }}
            </div>
        </div>
        <IconNavUserGuest v-else />
    </a>
</template>

<script setup>
import { computed, onMounted } from "vue";
import IconNavUserAuthenticated from "@svg/icons/icon-nav-user-authenticated.svg";
import IconNavUserGuest from "@svg/icons/icon-nav-user-guest.svg";
import router from "~/router/index.js";
import { useAuthStore } from "~/store/auth.js";

const authStore = useAuthStore();

onMounted(() => {
    // If the 'guest' flag was persisted (saying that the user was logged in before)
    // but there is no user object, try to fetch the user from the backend
    if (!authStore.guest && !authStore.hasUser) {
        authStore.revalidate();
    }
});

const initials = computed(() => {
    if (!authStore.hasUser) return "";
    return (
        authStore.user.firstName.substring(0, 1).toUpperCase() +
        authStore.user.lastName.substring(0, 1).toUpperCase()
    );
});

const goToProfile = () => {
    if (router.currentRoute.value.path !== "/") {
        return router.push({ path: "/app/profil" });
    }

    window.location.href = "/app/profil";

    // authStore.revalidate();
    // if (authStore.isLoggedIn) {
    //     window.location.href = '/app/profil';
    // } else {
    //     window.location.href = '/app/login';
    // }
};
</script>
