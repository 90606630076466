import {
    intervalToDuration,
    formatDuration,
    addMinutes,
    format,
} from "date-fns";
import { de } from "date-fns/locale";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const parseUtcDateFromBackend = (date) => {
    return utcToZonedTime(date, "Europe/Zurich");
};

export const convertDateToUtcForBackend = (date) => {
    return zonedTimeToUtc(date, "Europe/Zurich");
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatTime = (date) => {
    return format(date, "HH:mm");
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatDayOfMonth = (date) => {
    return format(date, "d. MMMM", { locale: de });
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatMonth = (date) => {
    return format(date, "MMMM", { locale: de });
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatDateISO = (date) => {
    return format(date, "yyyy-MM-dd");
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatMonthDate = (date) => {
    return format(date, "yyyy-MM-01");
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatDay = (date) => {
    return format(date, "d");
};

/**
 *
 * @param int minutes
 * @returns string
 */
export const formatTimeDuration = (minutes) => {
    return formatDuration(
        intervalToDuration({
            start: new Date(),
            end: addMinutes(new Date(), minutes),
        }),
        { locale: de },
    );
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatICSDate = (date) => {
    // return format(date, 'yyyyMMdd\'T\'HHmmss\'Z\'');
    // removed timezone from entry
    return format(date, "yyyyMMdd'T'HHmmss");
};

/**
 *
 * @param Date date
 * @returns string
 */
export const formatDayOfWeek = (date) => {
    return format(date, "EEEE", { locale: de });
};
