<script>
import { useConfigStore } from "~/store/config";
import FlashMessage from "~/components/global/FlashMessage.vue";
import ConfirmDialog from "~/components/global/ConfirmDialog.vue";
import CheckinSuccessModal from "~/components/global/CheckinSuccessModal.vue";
import LoadingSpinner from "~/components/global/LoadingSpinner.vue";

export default {
    components: {
        FlashMessage,
        ConfirmDialog,
        CheckinSuccessModal,
        LoadingSpinner,
    },

    setup() {
        const configStore = useConfigStore();
        configStore.getConfig();
    },

    data() {
        return { isLoading: true };
    },

    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 500);
    },
};
</script>
